<template>
    <div class='demo'>
      <div style="display: flex;">
          <Headbuttom></Headbuttom>
          <div @click="handFromshow"><el-input
          size="small"
          class="dev_dowm"
          placeholder=""
          suffix-icon="el-icon-arrow-down"
          :readonly="true"
          >
        </el-input></div>
      </div>

      <div class="attheadfrom" v-show="flag">
      <el-form
        :inline="true"
        size="small"
        :model="searchParams"
        class="demo-form-inline"
      >
        <el-form-item label="设备序列号">
          <el-input
            v-model="searchParams.serial_number"
            
          ></el-input>
        </el-form-item>
        <el-form-item label="设置安装位置">
          <el-input
            v-model="searchParams.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="报警时间">
          <el-date-picker
            v-model="searchParams.times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="处理时间">
          <el-date-picker
            v-model="searchParams.times2"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("devtable.cx")
          }}</el-button>
        </el-form-item>
      </el-form>
      </div>

      <Tables @handlerow="handlerow"
      @handlpages="handlpages"
      @handleSizeChange="handleSizeChange"
      :tableLabel="tableLabel"
      :tableData="tableData"
      :flags="flags"
      :operation="operation"
      :configs="configs"></Tables>

      <!-- 处理是弹框 -->

      <el-dialog
        title="报警事件描述"
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
        :before-close="handleClose"
        center
        >
        <div style="margin:10px 0">
          <el-input type="textarea" rows="4" v-model="remark"></el-input>
        </div>
        <p style="text-align: right;">处理人：{{UserInfo.user_name_save}}</p>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="handModl">确 定</el-button>
        </span>
      </el-dialog>


    </div>
</template>

<script>
import Tables from '@/components/table'
import {formatDate} from '../../utils/date'
export default {
    components:{
        Tables
    },
    data(){
        return{
            dialogVisible: false,
            remark:'',
           flag:false,
           searchParams:{
             name:'',
             serial_number:'',
             times:'',
             times2:'',
             alarm_start_time:'',
             alarm_end_time:'',
             handle_start_time:'',
             handle_end_time:''
           },
           UserInfo:JSON.parse(window.localStorage.getItem('user')),
          tableLabel:[
            {
              prop: "send_time",
              label: '报警时间',
              align: "center",
            },
            {
              prop: "name",
              label: '设备安装位置',
              align: "center",
            },
            {
              prop: "serial_number",
              label: '设备序列号',
              align: "center",
            },
            {
              prop: "type",
              label: '报警类型',
              align: "center",
            },
            {
              prop: "id",
              label: '报警图像',
              align: "center",
            },
            {
              prop: "state",
              label: '已处理',
              align: "center",
            },
            {
              prop: "handle_time",
              label: '处理时间',
              align: "center",
            },  
          ],
          operation:{
            width:200,
            data:[
              {
              name: "处理",
                type: "primary",
              },
              {
                name: "抓拍",
                type: "primary",
              },
            ]
          },
          tableData:[],
          // 分页数据
          configs: {
            page_index:1,
            pagesize: 10,
            total: 200,
            loading: false, // 加载状态
          },
          flags: {
            show: false, // 多选是否显示
            ishow: true, // 序号是否显示
          },
          v:'',
        }
    },

    mounted(){
      this.GetreDprodata()
    },
    methods:{
      handFromshow () {
        this.flag = !this.flag
      },
      onSubmit(){
        this.GetreDprodata()
      },
      // 列表数据
      GetreDprodata(){

        let data={
          method:'co.ipc.alarm.list',
          page_index:this.configs.page_index,
          page_size:this.configs.page_size,
          agent_id:this.UserInfo.agent_id,
          name:this.searchParams.name,
          serial_number:this.searchParams.serial_number,
          alarm_start_time:new Date(this.searchParams.times[0]).getTime()/1000,
          alarm_end_time:new Date(this.searchParams.times[1]).getTime()/1000,
          handle_start_time:'',
          handle_end_time:''
        }
        console.log(data)
        this.$serve(data).then(res=>{
          if(res.data.code==0){
            this.tableData = res.data.data
            this.tableData.forEach(el=>{
               el.send_time = formatDate(new Date(el.send_time * 1000), 'yyyy-MM-dd hh:mm')
               el.handle_time = formatDate(new Date(el.handle_time * 1000), 'yyyy-MM-dd hh:mm')
               el.state ==1 ? el.state = '已处理':"未处理"
            })
            this.configs.total = res.data.count
          }
        })
      },
      handlerow(v,name){
        console.log(v)
        if(name=="处理"){
          this.v = ""
          this.dialogVisible = true
          this.v = v
        }
      },
      // 处理
      handModl(){   
          let data={
             method:'co.ipc.alarm.handle',
             agent_id:this.UserInfo.agent_id,
             device_id:this.v.device_id,
             remark:this.remark,
             manager_id:this.UserInfo.manager_id,
             id:this.v.id
           }
           this.$serve(data).then(res=>{
             if(res.data.code==0){
                this.$message({
                  type: "success",
                  message:"处理成功",
                })
                this.GetreDprodata();
             }else{
                this.$message({
                  type: "error",
                  message:"处理失败",
                });
             }
           })
           this.dialogVisible =false
      },
      handleClose(){
        this.dialogVisible = false
      },
      handlpages(v){
        this.configs.page_index = v
        this.GetreDprodata()
      },
      handleSizeChange(){

      }
    }
}
</script>
<style scoped>
.demo{
    background-color: #fff;
    padding: 20px;
    
}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
</style>